.breadcrumb {
    background-color: transparent;
    padding-left: 0;
    padding-right: 0;
    .breadcrumb-item {
        a {
            color: $grey;
            &:hover {
                color: darken($grey, 0.2);
            }
        }
        &.active {
            color: $secondary-main;
        }
    }
}