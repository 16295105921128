a {
    cursor: pointer;
    &:hover {
        text-decoration: none;
    }
}
@mixin link($color) {
    color: $color;
    font-weight: 600;
    cursor: pointer;
    &:hover {
        color: darken($color, 10%);
    }
}

.link-p-main {
    @include link($primary-main);
}

.link-p-light {
    @include link($primary-light);
}

.link-s-main {
    @include link($secondary-main);
}

.link-icon {
    margin-left: 10px;
}