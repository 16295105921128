*, body {
    padding: 0;
    margin: 0;
}
body {
    background-color: #F7F8FC;
    font-family: 'Roboto', sans-serif;
    color: #192A34;
}

.mb-50 {
    margin-bottom: 50px;
}

.mb-30 {
    margin-bottom: 30px;
}

img {
    border-radius: 10px;
    object-fit: cover;
}

.click {
    cursor: pointer;
}