@mixin box-shadow($y, $blur) {
    box-shadow: 0 $y $blur rgba($primary-dark, $alpha: 0.2);
}

.box-shadow-30 {
    @include box-shadow(8px, 30px);
}

.box-shadow-5 {
    @include box-shadow(3px, 5px);
}