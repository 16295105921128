/* Mixins */
@mixin button {
    padding: 15px 25px;
    text-transform: capitalize;
    transition: .7s ease;
    cursor: pointer;
    &:hover {
        transition: .7s ease;
    }
}

@mixin filledButton($color) {
    @include button;
    background-color: $color;
    color: #fff;
    &:hover {
        background-color: darken($color, 10%);
        color: #fff;
    }
}

@mixin outlineButton($color) {
    @include button;
    border: 1px solid $color;
    color: $color;
    &:hover {
        background-color: $color;
        color: #fff;
        border-radius: 1px solid #fff;
    }
}

@mixin carouselButton($background, $color) {
    border-radius: 100%;
    padding: 30px;
    font-size: 24px;
    background-color: $background;
    color: $color;
    cursor: pointer;
}

.btn-p-main {
    @include filledButton($primary-main);
}

.btn-s-main {
    @include filledButton ($secondary-main);
}

.btn-p-dark {
    @include filledButton ($primary-dark);
}

.out-p-main {
    @include outlineButton($primary-main);
}

.out-s-main {
    @include outlineButton ($secondary-main);
}

.out-p-dark {
    @include outlineButton ($primary-dark);
}

.car-btn-dark {
    @include carouselButton(rgba($primary-light, 50%), #fff);
    &:hover {
        color: #fff;
        background-color: $primary-light;
    }
}

.car-btn-light {
    @include carouselButton(#fff, $primary-dark);
    &:hover {
        box-shadow: 0px 3px 5px rgba($primary-dark, 10%);
    }
}

@mixin car-btn-dark {
    @include carouselButton(rgba($primary-light, 50%), #fff);
    &:hover {
        color: #fff;
        background-color: $primary-light;
    }
}

@mixin car-btn-light {
    @include carouselButton(#fff, $primary-dark);
    &:hover {
        box-shadow: 0px 3px 5px rgba($primary-dark, 10%);
    }
}

.btn-icon {
    margin-left: 10px;
}