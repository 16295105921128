section, .custom-section {
    padding-top: 30px;
    padding-bottom: 30px;
}
@include media-breakpoint-up(md) { 
    .wrapper {
        margin-left: 30px;
        margin-right: 30px;
    }
    
    section, .custom-section {
        padding-top: 50px;
        padding-bottom: 50px;
    }
}

@include media-breakpoint-up(lg) { 
    .wrapper {
        margin-left: 65px;
        margin-right: 65px;
    }
    
    section, .custom-section {
        padding-top: 80px;
        padding-bottom: 80px;
    }
}