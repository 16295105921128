/* Color Palette */
$primary-dark : hsl(202, 35%, 15%);
$primary-main: hsl(202, 35%, 25%);
$primary-light: hsl(202, 35%, 50%);
$primary-lighter: hsl(202, 35%, 65%);
$primary-lightest: hsl(202, 35%, 85%);


$secondary-main: hsl(43, 81%, 45%);
$secondary-lighter: hsl(43, 81%, 60%);

$grey: hsl(201, 20%, 40%);
$grey-light: hsl(0, 0%, 77%);
$grey-lighter: hsl(0, 0%, 90%);

$background: hsl(180, 4%, 95%);
$background-light: hsl(228, 45%, 98%);