h1,h2,h3,h4,h5,h6 {
    font-family: 'Merriweather', serif;
    font-weight: 700;
    text-transform: capitalize;
}

h1 {
    font-size: calc(100% + 1vw + 1vh);
}

h2 {
    font-size: calc(100% + 0.5vw + 0.5vh);
}

h3 {
    font-size: calc(100% + 0.25vw + 0.25vh);
}

.txt-primary {
    color: $primary-main;
}

.txt-p-light {
    color: $primary-light;
}

.txt-p-lighter {
    color: $primary-lighter;
}

.txt-p-lightest {
    color: $primary-lightest;
}


.txt-secondary {
    color: $secondary-main;
}

.txt-grey {
    color: $grey;
}

.txt-dark {
    color: $primary-dark;
}

.title {
    font-weight: 700;
    text-transform: capitalize;
}

.capitalize {
    text-transform: capitalize;
}

.uppercase {
    text-transform: uppercase;
}

.text-font {
    font-family: 'Roboto', sans-serif;
}

.title-font {
    font-family: 'Merriweather', serif;
}