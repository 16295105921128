.input-btn {
  background: $background-light;
  color: $secondary-main;
  font-weight: 600;
  border: 1px solid $grey-lighter;
}

.form-control {
  background: $background-light;
  border-color: $grey-lighter;
  padding: 8px 15px;
  
  &::placeholder {
    color: $grey-light;
  }
}

.input-group-text {
  border-color: $grey-lighter;
}