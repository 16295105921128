/* Mixins */
@mixin badge($color) {
    padding: 5px 10px;
    background-color: $color;
    color: #fff;
    font-weight: 600;
    text-transform: uppercase;
}

.badge-p-main {
    @include badge($primary-main);
}

.badge-s-main {
    @include badge ($secondary-main);
}

.badge-p-dark {
    @include badge ($primary-dark);
}

.badge-icon {
    margin-left: 10px;
}