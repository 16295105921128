table {
    // border: 1px solid $secondary-main;
    // border-radius: 10px;
    thead {
        background-color: $background-light;
        th {
            border: 1px solid $secondary-main;
            &:first-child {
                border-right: none;
            }
            &:last-child {
                border-left: none;
            }
        }
    }
    tr {
        border-right: 1px solid $secondary-main;
        border-left: 1px solid $secondary-main;
        &:nth-child(odd) {
            background-color: #fff;
        }
        &:nth-child(even) {
            background-color: $background-light;
        }
        &:last-child {
            border-bottom: 1px solid $secondary-main;
        }
        fa-icon {
            color: $grey;
        }
        td {
            &:nth-child(1) {
                font-weight: 600;
            }
        }
    }
}