@mixin overlay($color, $alpha) {
    &::after {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background-color: $color;
        opacity: $alpha;
        z-index: 10;
    }
}
.dark-overlay {
    @include overlay($primary-dark, 0.50);
}